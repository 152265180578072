import React, { useState } from 'react';

import styles from "./styles.module.css";

import arrowDownIcon from "../../images/arrow-down.svg";
import arrowUpIcon from "../../images/arrow-up.svg";
import { time } from '../../utils/cookie-time';


export const Select = ({
  tags,
  setTime,
  extraClass = "",

  error,
}) => {

  const [array, setArray] = useState(time);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [text, setText] = useState('');

  const arrowIcon = isPopupOpen ? arrowUpIcon : arrowDownIcon;

  const onTogglePopup = () => {
    setIsPopupOpen(!isPopupOpen);
  };

  React.useEffect(() => {
    if (tags.length > 0 && tags[0].value === true) {
      setArray(time.slice(0,3))
    }

    if (tags.length > 0 && tags[1].value === true) {
      setArray(time.slice(0,6))
    }
    if (tags.length > 0 && tags[2].value === true) {
      setArray(time.slice(0,9))
    }if (tags.length > 0 && tags[3].value === true) {
      setArray(time)
    }
    
  }, [tags])

  const onChange = (item) => {
    setIsPopupOpen(false)
    setText(item.text);

    setTime(item.text)

  }
  return (
    <div className={`${styles.content} ${extraClass}`}>
      <button
        onClick={onTogglePopup}
        type="button"
        className={styles.btn}
      >
        <p
          className={`text text_type_medium-16 text_color_${
            "primary"
          } ${styles.text}`}
        >
          {text}
        </p>
        <img className={styles.img} src={arrowIcon} alt="Открыть список." />
      </button>
      {isPopupOpen && (
        <div className={styles.select__box}>
          <div className={styles.achievements_list}>
            {array.map((item) => {
              return (
                <p
                  className={styles.option}
                  key={item.id}
                  id={item.id}
                  onClick={() => onChange(item)}
                >
                  {item.text}
                </p>
              );
            })}
          </div>
        </div>
      )}
      {error && (
        <span
          className={`text text_type_medium-16 text_color_red mb-4 ${styles.error}`}
        >
          {error}
        </span>
      )}
    </div>
  );
}