import styles from './styles.module.css'

const IngredientsSearch = ({ ingredients, onClick }) => {
  return <div className={styles.container}>
    {ingredients.map(({ name, id, measurement_unit, is_vegan, is_gluten_free }) => {
      return <div key={id} onClick={_ => onClick({ id, name, measurement_unit, is_vegan, is_gluten_free })}>{name}</div>
    })}
  </div>
}

export default IngredientsSearch