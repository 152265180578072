import { useState, useEffect } from 'react';

import plus from '../../images/user-plus.svg';
import checkbox from '../../images/user-check.svg';
import styles from './option.module.css'

export const Option = ({
  checked,
  text
}) => {
  return (
    <button disabled className={`${styles.option} ${checked && styles["option_checked"]}`}>
      <img src={checked ? checkbox : plus} alt='Иконка' />
      {text}
    </button>
  )
}