export const time = [
  {
    id: 1,
    text: '10'
  },
  {
    id: 2,
    text: '20'
  },
  {
    id: 3,
    text: '30'
  },
  {
    id: 4,
    text: '40'
  },
  {
    id: 5,
    text: '50'
  },
  {
    id: 6,
    text: '60'
  },
  {
    id: 7,
    text: '70'
  },
  {
    id: 8,
    text: '80'
  },
  {
    id: 9,
    text: '90'
  },
  {
    id: 10,
    text: '100'
  },
  {
    id: 11,
    text: '110'
  },
  {
    id: 12,
    text: '120'
  },
]